import axios from '@/config/httpConfig'

//获取查重界面信息
export function getSetcondition(data) {
  return axios.post('/repeatMerge/setcondition', data)
}
//查询对象重复数据
export function getReDataList(data) {
  return axios.post('/repeatMerge/getReDataList', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

// 保存视图
export function SaveView(data) {
  return axios.post('/view/saveView', data)
}
// 删除视图
export function DeleteGroup(data) {
  return axios.post('/view/deleteView', data)
}
// 获取视图详细信息
export function GetViewInfo(data) {
  return axios.post('/view/getViewInfo', data)
}
//重置列宽
export function ResettingViewFieldMemory(data) {
  return axios.post('/view/list/resettingViewFieldMemory', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
  return axios.post('/currency/getCurrencyInfo', data)
}
//获取一组重复数据
export function getOneGroupData(data) {
  return axios.post('/repeatMerge/getOneGroupData', data)
}
//显示每组的重复的详细数据
export function getOneGroupDataDetail(data) {
  return axios.post('/repeatMerge/showReDataDetail', data)
}
//合并重复数据
export function toMergeReData(data) {
  return axios.post('/repeatMerge/mergeReData', data)
}

// 获取视图列表 (左侧下拉菜单列表,) // 获取对象下所有可见的公海池
export function queryMarketSeaList(data) {
  return axios.post('/marketsea/queryMarketSeaList', data)
}


// 获取视图列表表头   获取公海池列表表头及按钮权限 包含按钮等,左侧名称
export function queryHeaderByMarketId(data) {
  return axios.post('/marketsea/queryHeaderByMarketId', data)
}


// 获取视图列表记录（表格数据）
export function queryDataByMarketId(data) {
  return axios.post('/marketsea/queryDataByMarketId', data)
}


// 领取与分配 
export function marketseaCollectionAndDistribution(data) {
  return axios.post('marketseaFunction/marketseaCollectionAndDistribution', data)
}
// 获取用户有无报表相关的权限的结果
export function getReportEnableList(data) {
  return axios.post('/report/getReportEnableList', data)
}
//退回与回收
export function marketseaReturnAndRecovery(data) {
  return axios.post('marketseaFunction/marketseaReturnAndRecovery', data)
}

// 获取公海池成员  分配时使用(下拉选项)
export function getMarketseaSpecMem(data) {
  return axios.post('marketseaFunction/getMarketseaSpecMem', data)
}

// 转移公海池
export function marketseaTransfer(data) {
  return axios.post('marketseaFunction/marketseaTransfer', data)
}
// 转移公海池时查询可转入的公海池id(下拉选项)
export function selMarketsea(data) {
  return axios.post('marketseaFunction/selMarketsea', data)
}

//废弃与恢复
export function marketseaAbandonmentAndRecovery(data) {
  return axios.post('marketseaFunction/marketseaAbandonmentAndRecovery', data)
}
// 视图列表退回（公海池）
export function getReturnSeasPool(data) {
  return axios.post('/marketseaFunction/getReturnSeasPool', data)

}

//判断订单是否为已开具发票状态
export function isAlreadyGenerated(data) {
  return axios.post('/generateInvoiceNew/isAlreadyGenerated', data)
}
// 判断全部订单是否为同一个客户
export function isSameCustomer(data) {
  return axios.post('/generateInvoiceNew/isSameCustomer', data)
}
//判断全部订单是否为激活状态
export function isActiveOrder(data) {
  return axios.post('/generateInvoiceNew/isActiveOrder', data)
}
//邮件发送页
export function getEmailPageInfo(data) {
  return axios.post('emailObject/getEmailPageInfo', data)
}

// 批量打分
export function BatchLeadScore(data) {
  return axios.post('/leadscore/batchLeadScore', data)
}

// 获取MailChimp权限
export function GetMailchimpGetMailChimpPermission(data) {
  return axios.post('/mailchimp/getMailChimpPermission', data)
}
//合并记录接口
export function postCombination(data) {
  return axios.post('/duplication/merge', data)
}
// 获取需要合并的数据
export function merge(data) {
  return axios.post('/duplication/getDataByIds', data)
}
// 查询语言接口
export function queryLanguage(data) {
  return axios.post("/knowledgeTranslate/queryLanguage", data);
}
// 获取快速操作错误信息
export function getErrorInfo(data) {
  return axios.post('/KnowledgeArticle/getErrorInfo', data)
}
// 当前是否启用了quickbooks功能  查询QB权限
export function quickbookStart(data) {
  return axios.get('/quickbooks/getQBPermission', data)
}
// 从quickbooks上更新状态
export function updateStatusFromQB(data) {
  return axios.post('/quickbooks/updateStatusFromQB', data)
}
// 从quickbooks上同步发票信息
export function sendInvoiceFromQB(data) {
  return axios.post('/quickbooks/sendInvoiceFromQB', data)
}
// 订单同步到QuickBooks
export function cloudccOrderToQBEstimate(data) {
  return axios.post('/quickbooks/cloudccOrderToQBEstimate', data)
}
// 回款同步到QuickBooks
export function cloudccPaymentToQBPayment(data) {
  return axios.post('/quickbooks/cloudccPaymentToQBPayment', data)
}
// 从QuickBooks同步发票
export function syncInvoicesFromQB(data) {
  return axios.post('/quickbooks/syncInvoicesFromQB', data)
}
// 从QuickBooks同步回款
export function syncPaymentFromQB(data) {
  return axios.post('/quickbooks/syncPaymentFromQB', data)
}
// 同步至quickbooks
export function cloudccInvoicesToQBInvoices(data) {
  return axios.post('/quickbooks/cloudccInvoicesToQBInvoices', data)
}

// 获取记录类型
export function getRecordType(data) {
  return axios.post('/batch/getRecordType', data)
}

// 获取批量新增布局信息
export function getBatchAdding(data) {
  return axios.post('/batch/getBatchInfoToAdd', data)
}

// 添加或更新对象记录
export function save(data) {
  return axios.post('/objectInfo/save', data)
}

// 获取选项列表值
export function getSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
  return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}
 
// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
  return axios.post('objectdetail/queryMoreDataByName', data)
}

// 获取批量删除的数据
export function batchDelete(data) {
  return axios.post('/batch/batchDelete', data)
}

// 自定义按钮
export function openCall(data) {
  return axios.post('/openCall/common', data)
}

// 查询项目模板
export function queryProjectTemplateList(data) {
  return axios.post('/projectTemplate/queryProjectTemplateList', data)
}

// 项目模板保存接口
export function templateSave(data) {
  return axios.post('/projectTemplateOperation/templateSave', data)
}

// 获取用户界面设置
export function getUserSetup(data) {
  return axios.get('/user/getUserSetup', data)
}

// 获取对象权限
export function getObjectPermission(data) {
  return axios.post('objectdetail/getObjectPermission', data)
}

// 更改所有人是否展示伙伴云和队列
export function checkQueueAndPartner(data) {
  return axios.post('/objectdetail/checkQueueAndPartner', data)
}

// 队列
export function queryQueue(data) {
  return axios.post('/objectdetail/queryQueue', data)
}

// 获取对象信息
export function getObjectGetObjectInfo(data) {
  return axios.post('/object/getObjectInfo', data)
}

//判断所选订单是否属于同一个币种以及价格手册
export function isSameCurencyAndPriceBook(data) {
  return axios.post('/generateInvoiceNew/isSameCurencyAndPriceBook', data)
}