<template>
  <div class="cnki" v-loading="load">
    <div class="topTitle">
      <!-- 查重合并 -->
      {{
        prefix == "002" ? $t("label.same.analyze") : $t("label.mergeRechecking")
      }}
    </div>
    <div class="ruleTitle">
      <div>
        <!-- 请输入查询数据的范围和规则，点击[查重]按钮，得到符合条件的重复数据 -->
        {{ $t("label.mr.descreption.condition1") }}
      </div>
      <div>
        <!-- 规则-按前几个字符：请输入数字，按输入数字的前几个相同字符查询重复数据 -->
        {{ $t("label.mr.descreption.condition2") }}
      </div>
      <div>
        <!-- 规则-是否完全匹配：勾选此项，按字段的相同数据查询重复数据。勾选此项后，按前几个字符输入将失败 -->
        {{ $t("label.mr.descreption.condition3") }}
      </div>
    </div>
    <div class="rangeTitle">
      <!-- 筛选查重范围(若不添加条件则查询全部数据) -->
      {{ $t("label.mr.caption.scope") }}
    </div>
    <!-- 筛选查重范围 -->
    <div>
      <div class="range">
        <!-- 筛选字段文本框 -->
        <el-form>
          <el-form-item
            v-for="(val, index) in pushData"
            :key="val.id"
            :label="index + 1 + '.'"
            label-width="50px"
          >
            <div class="vl">
              <!-- 筛选字段 开始 -->
              <el-select
                clearable
                v-model="val.fieldId"
                :placeholder="$t('label.selectfield')"
                filterable
                popper-class="option"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="(items, indexs) in choseFieldList"
                  :key="indexs"
                  :label="items.labelName"
                  :value="items.id"
                  @click.native="handleCommand(val, items, items)"
                >
                  <!-- val:传递当前字段的信息 -->
                  <!-- items:传递 -->
                </el-option>
              </el-select>
              <!-- 筛选条件 开始 -->
              <el-select
                clearable
                v-model="val.op"
                :placeholder="$t('label.report.select.condition')"
                class="select"
                filterable
                popper-class="option"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in conditions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <span>
              <!-- 值 -->
              {{ $t("label.campaign.channel.value") }}
            </span>
            <EditableCell
              style="
                width: 200px;
                display: inline-block;
                margin-left: 10px;
                position: relative;
              "
              ref="editableCell"
              :options="selector"
              :value="val.val"
              :input-type="val.inputType"
              :prefix="prefix"
              :fieldId="val.fieldId"
              :objid="val.objid"
              :min="0"
              :max="1000000000000000000"
              :origin-type="filter"
              @selectes="val.fieldId === '' ? '' : selectes(val)"
              @remoteSearch="remoteSearch(val, index)"
              @remoteMethods="remoteMethods"
            >
              {{ val.fieldId }}
            </EditableCell>
            <!-- 加减按钮组 -->
            <div style="display: inline-block; margin-top: 5px">
              <el-button
                type="info"
                size="mini"
                @click="updateItem('reduce', index)"
                v-if="showBut[index]"
                >-</el-button
              >
              <!-- <button type=" button" @click="updateItem('reduce', index)" v-if="showBut[index]"></button> -->
              <el-button
                type="info"
                size="mini"
                @click="updateItem('add', index)"
                v-if="index + 1 == pushData.length"
                >+</el-button
              >
              <!-- <button type="button" @click="updateItem('add', index)" v-if="index + 1 == pushData.length">
                +
              </button> -->
            </div>
          </el-form-item>
        </el-form>
        <div>
          <el-dialog
            :title="$t('label.searchs')"
            custom-class="dialog-form-wrapper"
            width="60%"
            top="5%"
            style="height: 91%"
            :visible.sync="showSearchTable"
            :lock-scroll="true"
            :close-on-click-modal="false"
            :modal="true"
            append-to-body
          >
            <search-table
              ref="searchTable"
              :fieldId="fieldId"
              :checked="checked"
              :relevant-objid="relevantObjId"
              :relevant-prefix="relevantPrefix"
              @changeSelect="changeSelect"
            />
          </el-dialog>
        </div>
      </div>
    </div>
    <!-- 高级筛选范围 -->
    <div style="display: flex; margin-top: 20px"></div>
    <!-- 设置查重规则 -->
    <div style="margin-top: 10px">
      <!-- title -->
      <div style="margin: 20px 0px">
        <span style="color: #c23934">*</span>
        <!-- 设置查重规则 -->
        {{ $t("vue_label_checkduplicate_rules") }}
      </div>
      <!-- 重复条件 -->
      <div class="condition">
        <div style="width: 260px">
          <!-- 判定重复条件 -->
          {{ $t("vue_label_checkduplicate_repeat_conditions") }}
        </div>
        <div style="margin-left: 25px">
          <input
            style="left: -30px"
            type="radio"
            name="name"
            ref="choseAll"
            checked
          />
          <!-- 同时满足全部 -->
          {{ $t("vue_label_checkduplicate_at_same_time") }}
        </div>
        <div style="margin-left: 55px">
          <input
            style="left: -30px"
            type="radio"
            ref="choseSingle"
            name="name"
          />
          <!-- 满足任意一条 -->
          {{ $t("vue_label_checkduplicate_any_one") }}
        </div>
      </div>
      <div>
        <!-- 说明区域 -->
        <div class="intro">
          <div>
            <input
              ref="choseField"
              type="checkbox"
              style="margin-left: 0px; margin-top: 5px"
              @change="choseAll"
              v-model="checkVal"
            />
          </div>
          <div style="margin-left: 10px; margin-top: 10px; width: 230px">
            <!-- 选择查重字段 -->
            {{ $t("label.checkfield.choosetab") }}
          </div>
          <div style="margin-left: 40px; margin-top: 10px">
            <!-- 设置重复规则 -->
            {{ $t("vue_label_checkduplicate_set_repeat_rules") }}
          </div>
        </div>
        <!-- 查重字段区域 -->
        <div>
          <!-- 要进行循环的元素 -->
          <div
            v-for="(item, index) in selectedFieldList"
            :key="index"
            style="display: flex"
          >
            <!-- 复选框 -->
            <div>
              <input
                :id="item.fieldId"
                type="checkbox"
                style="margin-left: 0px; margin-top: -5px"
                ref="filterField"
                :data-fieldId="item.fieldid"
                @change="choseDefault($event, index)"
              />
            </div>
            <!-- 查重字段 -->
            <div
              style="
                margin-left: 10px;
                width: 230px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ item.labelName }}
            </div>
            <!-- 单选框 -->
            <div style="margin-left: 30px">
              <input
                ref="checkAll"
                type="radio"
                style="margin-top: -5px"
                :name="item.fieldid"
                :data-fieldId="item.fieldid"
                data-type="all"
                @change="choseDefaultField($event, this)"
              />
            </div>
            <!-- 文字 -->
            <div style="margin-left: 5px">
              <!-- 验证全部字符 -->
              {{ $t("vue_label_checkduplicate_verify_all_characters") }}
            </div>
            <!-- 单选框 -->
            <div style="margin-left: 50px">
              <input
                type="radio"
                style="margin-top: -5px"
                :name="item.fieldid"
                ref="checkChar"
                :data-fieldId="item.fieldid"
                data-type="part"
                @change="choseDefaultField($event, this)"
              />
            </div>
            <!-- 文字 -->
            <div style="margin-left: 5px">
              <!-- 验证前 -->
              {{ $t("vue_label_beforeverification") }}
            </div>
            <!-- 文本框 -->
            <div>
              <input
                v-model="item.chars"
                type="text"
                ref="charCount"
                :disabled="item.readonly"
                style="
                  width: 25px;
                  height: 17px;
                  margin-left: 5px;
                  padding-left: 3px;
                  font-size: 12px;
                  text-align: center;
                "
              />
            </div>
            <!-- 文字 -->
            <div style="margin-left: 5px">
              <!-- 个字符 -->
              {{ $t("message.duplication.label.nletters") }}
            </div>
          </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="bottomButton">
        <el-button type="primary" @click="openResult">
          <!-- 查重 -->
          {{ $t("vue_label_checkduplicate_checkduplicate") }}
        </el-button>
        <el-button @click="goBack">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import operator from "@/config/enumCode/operator.js";
import inputType from "@/config/enumCode/inputType.js";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import {
  GetViewInfo,
  GetViewGetSelectValue,
  GetCurrencyInfo,
  getSetcondition,
  getLookupInfo,
  queryMoreDataByName,
  getSelectValue,
  getRecordType,
} from "./api.js";
export default {
  components: {
    EditableCell,
  },
  watch: {
    pushData(val) {
      if (val.length == 1) {
        this.showBut[0] = false;
      }
    },
  },
  data() {
    return {
      obj: {},
      load: false,
      // num: 0,
      // 保存当前对象的前缀
      prefix: this.$route.query.prefix,
      objApi: this.$route.query.objApi,
      // 保存当前视图id
      viewId: this.$route.query.viewId,
      // 保存当前对象id
      objId: this.$route.query.objId,
      // 保存查重设置字段
      selectedFieldList: [],
      // 是否选择查重字段
      checkVal: false,
      // 保存筛选字段数组
      choseFieldList: [],
      // 保存筛选字段
      pushData: [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
          objid: "",
        },
      ],
      pushDatas: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        inputType: "input",
        objid: "",
      },
      // 保存查重字段规则
      fieldRules: [],
      //保存单个查重字段设置规则模板
      fieldRule: {
        fieldname: "",
        chars: "",
        matchonly: "false",
      },
      // 运算符
      conditions: [],
      advancedFilter: false,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      viewInfo: [], //视图信息
      conditionAllFieldList: [], //条件字段
      Echo: [],
      selector: [],
      showSearchTable: false,
      checked: false, //多选框选择和取消
      rowIndex: 0,
      showBut: [false],
      filter: "filter",
    };
  },
  created() {},
  mounted() {
    this.load = true;
    this.choseField();
    this.setcondition();
  },
  methods: {
    async choseField() {
      let params = {
        objId: this.objId,
        viewId: this.viewId,
      };
      let result = await GetViewInfo(params);
      this.choseFieldList = result.data.conditionAllFieldList;
    },
    remoteMethods(val, fieldId) {
      let params = {
        fieldId: fieldId,
        objId: "",
        prefix: "",
      };
      getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selector = options;
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      let options = {
        label: row.data.id,
        value: row.data.name,
      };
      this.$set(this.pushData[this.rowIndex - 1], "val", options);
      this.showSearchTable = false;
    },
    remoteSearch(item, index) {
      if (item.fieldId !== "") {
        this.fieldId = item.fieldId;
      }
      this.rowIndex = index + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    choseDefaultField(event) {
      let id = event.srcElement.dataset.fieldid;
      let type = event.srcElement.dataset.type;
      if (type === "all") {
        this.selectedFieldList.map((Sitem) => {
          if (Sitem.fieldid == id) {
            this.$set(Sitem, "chars", "");
            this.$set(Sitem, "readonly", true);
          }
        });
      } else {
        this.selectedFieldList.map((Sitem) => {
          if (Sitem.fieldid == id) {
            this.$set(Sitem, "chars", "");
            this.$set(Sitem, "readonly", false);
          }
        });
      }
    },
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      } else {
        if (val.fieldId === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selector = arr;
          });
        }
      }
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue() {
      getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selector = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    async getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      let res = await getRecordType(params);
      if (res.result) {
        res.data.recordTypeList.forEach((item) => {
          let obj = {
            label: item.name,
            value: item.name,
          };
          arr.push(obj);
          this.selector = arr;
        });
      }
    },
    openResult() {
      // 如果开启了高级筛选
      // if (this.advancedFilter == true) {
      // 遍历参数数组
      let arrData = [];
      this.pushData.map((item) => {
        let object = {
          fieldId: item.fieldId,
          op: item.op,
          val: "",
          type: item.type,
        };
        arrData.push(object);
      });
      this.$refs.editableCell.forEach((item, index) => {
        if (item.editValue !== "") {
          arrData[index].val =
            arrData[index].type === "L"
              ? item.editValue.join(",")
              : item.editValue;
        } else {
          arrData[index].val =
            arrData[index].type === "L" ? item.value.join(",") : item.value;
        }
      });
      if (arrData.length > 0) {
        // 验证筛选器是否填写完整
        for (var key in arrData) {
          var item = arrData[key];
          if (item.fieldId !== "") {
            if (item.val.value == "" || item.op == "") {
              this.$message({
                showClose: true,
                type: "warning",
                // 请填写完整筛选条件
                message: this.$i18n.t(
                  "vue_label_checkduplicate_complete_filter_rules"
                ),
              });
              return;
            } else if (item.val.value != "" && item.op != "") {
              let str = "1";
              if (arrData.length == 1) {
                str = "1";
              } else if (arrData.length > 1) {
                arrData.map((item, index) => {
                  if (index >= 1) {
                    str += ` AND ${index + 1} `;
                    // str = str.replace(/AND $/, "");
                  }
                });
              }
              arrData.map((item) => {
                delete item["type"];
              });
              var conditionData = JSON.stringify({
                data: arrData,
                filter: str,
                mainObjId: "",
                relatedObjIdD: "",
              });
            }
          }
        }
      } else {
        conditionData = "";
      }
      // } else {
      //   conditionData = "";
      // }
      // 启用高级筛选 验证查重字段
      // 验证是否选择了查重字段，
      if (this.checkVal == false) {
        this.$message({
          showClose: true,
          type: "warning",
          // 请填写完整查重规则
          message: this.$i18n.t(
            "vue_label_checkduplicate_complete_check_rules"
          ),
        });
        return;
      } else {
        // 先获取所有被选中的查重字段
        let arr = this.$refs.filterField;
        arr = arr.filter((item) => {
          return item.checked == true;
        });
        // 获取所有的全部字符单选框
        let allChar = this.$refs.checkAll;
        this.$refs.checkAll = allChar.filter((item) => {
          return item.checked == true;
        });
        // 获取所有的字符数量单选框
        let choseChar = this.$refs.checkChar;
        choseChar = choseChar.filter((item) => {
          return item.checked == true;
        });
        let count = 0;
        choseChar.map((item) => {
          this.selectedFieldList.map((Sitem) => {
            if (item.dataset.fieldid == Sitem.fieldid && Sitem.chars == "") {
              count++;
            }
          });
        });
        if (count > 0) {
          this.$message({
            showClose: true,
            type: "warning",
            // 请填写完整查重规则
            message: this.$i18n.t(
              "vue_label_checkduplicate_complete_check_rules"
            ),
          });
          return;
        }
        // 设置逻辑关系
        // 获取两个单选框\
        let relation = "AND";
        if (this.$refs.choseAll.checked == true) {
          relation = "AND";
        } else {
          relation = "OR";
        }
        // 准备rules参数
        var rulesData = [];
        arr.map((item) => {
          this.selectedFieldList.map((Sitem) => {
            if (Sitem.fieldid == item.dataset.fieldid) {
              let matchonly = Sitem.chars == "" ? "true" : "false";
              let object = {
                fieldname: Sitem.schemefieldName,
                chars: Sitem.chars,
                matchonly: matchonly,
              };
              rulesData.push(object);
            }
          });
        });
        var rules = {
          rules: rulesData,
          rj_relation: relation,
        };
        rules = JSON.stringify(rules);
      }
      // 准备传递的参数
      let obj = {
        prefix: this.prefix,
        conditions: conditionData,
        rules,
      };
      obj = JSON.stringify(obj);
      sessionStorage.setItem("cnkiFirst", obj);
      this.$router.push({
        path: `/commonObjects/cnkiResult/${this.objApi}`,
      });
    },
    updateItem(handle, index) {
      if (handle == "add") {
        if (this.pushData.length <= 4) {
          this.pushData.push({
            ...this.pushDatas,
          });
          if (this.pushData.length >= 0 && this.pushData.length <= 1) {
            this.showBut = [false];
          } else {
            this.showBut.push(true);
            this.showBut[0] = true;
          }
        }
      } else {
        this.pushData.splice(index, 1);
        this.$refs.editableCell.splice(index, 1);
      }
    },
    // 获取查重页面信息
    async setcondition() {
      let obj = {
        prefix: this.prefix,
      };
      let result = await getSetcondition(obj);
      this.load = false;
      this.selectedFieldList = result.data.selectedFieldList;
      // 通过选中的字段id  匹配到数据  添加匹配的字符数
      this.selectedFieldList.map((item) => {
        this.$set(item, "chars", "");
        this.$set(item, "readonly", true);
      });
    },
    choseAll() {
      this.selectedFieldList.map((item) => {
        this.$set(item, "chars", "");
      });
      // 获取页面所有查重字段
      let array = [];
      array = this.$refs.filterField;
      // 获取后面所有的单选框
      let arr = [];
      arr = this.$refs.checkChar;
      for (var key in array) {
        if (this.checkVal) {
          array[key].checked = true;
          let id = array[key].dataset.fieldid;
          for (let index in arr) {
            if (arr[index].dataset.fieldid == id) {
              arr[index].checked = true;
            }
          }
          this.selectedFieldList.map((item) => {
            this.$set(item, "readonly", false);
          });
        } else {
          array[key].checked = false;
          let id = array[key].dataset.fieldid;
          for (let index in arr) {
            if (arr[index].dataset.fieldid == id) {
              arr[index].checked = false;
            }
          }
          this.selectedFieldList.map((item) => {
            this.$set(item, "readonly", true);
          });
        }
      }
    },
    choseDefault(event) {
      // 获得所有的查重字段复选框
      let arr = [];
      arr = this.$refs.filterField;
      let count = 0;
      // 遍历复选框
      arr.map((item) => {
        if (item.checked == false) {
          count++;
        }
      });
      if (count == this.selectedFieldList.length) {
        this.checkVal = false;
      } else {
        this.checkVal = true;
      }
      // 获取后面所有的单选框
      let array = [];
      array = this.$refs.checkChar;
      // 获取后面所有的全部单选框
      let allArray = [];
      allArray = this.$refs.checkAll;
      // 获取当前查重字段id
      let id = event.srcElement.dataset.fieldid;
      // 遍历所有的单选框
      for (var key in array) {
        // 如果当前复选框被选中
        if (event.srcElement.checked) {
          if (array[key].dataset.fieldid == id) {
            array[key].checked = true;
          }
          this.selectedFieldList.map((Sitem) => {
            if (Sitem.fieldid == id) {
              this.$set(Sitem, "readonly", false);
            }
          });
        } else {
          if (array[key].dataset.fieldid == id) {
            array[key].checked = false;
          }
          this.selectedFieldList.map((Sitem) => {
            if (Sitem.fieldid == id) {
              this.$set(Sitem, "readonly", true);
            }
          });
        }
      }
      for (let key in allArray) {
        // 如果当前复选框被选中
        if (event.srcElement.checked) {
          return;
        } else {
          if (allArray[key].dataset.fieldid == id) {
            allArray[key].checked = false;
          }
        }
      }
      // 如果当前字段被选中
      if (event.srcElement.checked == true) {
        this.checkVal = true;
      }
    },
    //判断选择添加如果选择字段获取的值为S则调用S对象,获取的值为D则调用D对象,获取的值为F则调用F对象,获取的值为O则调用O对象
    async handleCommand(item, key, index) {
      this.selector = [];
      let keys = "";
      if (key.schemefieldType === "Z") {
        keys = key.expressionType;
      } else {
        keys = key.schemefieldType;
      }
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      this.pushData.map((item, indexs) => {
        if (index.id === item.fieldId) {
          item.op = this.conditions[0].id;
          item.type =
            key.schemefieldType === "Z"
              ? key.expressionType
              : key.schemefieldType;
          this.$refs.editableCell[indexs].editValue = "";
          item.objid = key.schemetableId;
        }
      });
      this.pushData.forEach((items) => {
        if (item.id === items.id) {
          item.inputType = inputType[keys === "L" ? "Q" : keys];
        }
      });
      // 当前选中字段类型
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      if (keys == "ct") {
        let res = await GetCurrencyInfo();
        res.data.forEach((item) => {
          let obj = {
            label: item.currencyname,
            value: item.currencycode,
          };
          this.selector.push(obj);
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "重复列表") {
      //不需要替换多语言
      this.$route.meta.keepAlive = true;
    } else {
      this.$route.meta.keepAlive = false;
    }
    next();
  },
};
</script>

<style scoped lang="scss">
input {
  padding-left: 7px;
}
.cnki {
  margin: 10px;
  background: white;
  border-radius: 4px;
  min-height: 900px;
  font-family: SourceHanSansCN-Medium;
  color: #333333;
  padding-left: 23px;
}
.topTitle {
  padding: 23px 0px 23px 0px;
  font-size: 16px;
  font-weight: 700;
}
.ruleTitle {
  padding: 0px 0px 23px 0px;
  font-size: 14px;
}
.rangeTitle {
  font-size: 14px;
  font-weight: 600;
}
.condition div {
  display: flex;
  align-items: center;
}
.condition {
  display: flex;
  align-items: center;
}
.bottomButton {
  display: flex;
  text-align: center;
  margin-top: 50px;
  padding-left: 44%;
}
input {
  outline: none;
  border: 1px solid #dddbda;
  margin-left: 10px;
  height: 32px;
}
.range {
  margin-top: 10px;
}
.range button {
  width: 32px;
  height: 32px;
  margin-left: 10px;
}
.iconPosition {
  position: absolute;
  left: 150px;
  top: 8px;
}
.chosseField {
  width: 164px;
  height: 70px;
  background-color: pink;
  position: absolute;
  left: 10px;
  right: 0px;
}
.intro {
  display: flex;
}
.choseFieldList {
  font-family: SourceHanSansCN-Regular;
  font-size: 14px;
  color: #333333;
  padding-bottom: 20px;
  height: 400px;
  overflow: auto;
}
.choseFieldList li {
  padding: 20px 20px 0px 20px;
}
::v-deep .el-dropdown-menu {
  height: 400px;
  overflow: auto;
}
::v-deep .el-input__inner {
  height: 32px;
}
::v-deep .el-input__icon {
  line-height: 20px;
}
::v-deep .el-form-item__label {
  line-height: 32px;
}
.vl {
  display: inline-block;
}
::v-deep .el-form-item__content {
  line-height: 27px;
}
::v-deep .el-select {
  margin-right: 10px;
}
::v-deep .el-form-item__label {
  line-height: 42px;
}
::v-deep .el-form-item {
  margin-bottom: 5px;
}
::v-deep .remoteBtn {
  position: absolute;
  right: 8px;
  top: 2px;
}
::v-deep .el-button--mini,
.el-button--mini.is-round {
  padding: 7px 11px;
}
::v-deep .el-button--info {
  background: #ffffff;
  border-color: #dcdfe6;
}
::v-deep .el-button--info {
  color: black;
}
::v-deep .el-date-editor {
  display: none !important;
}
::v-deep .el-input {
  width: 200px !important;
}
::v-deep .el-textarea__inner {
  transform: translateY(4px);
  min-height: 32px !important;
}
::v-deep .remoteBtn1 {
  top: 3px;
}
</style>
